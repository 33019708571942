import React from "react";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";

const EventForm = (props) => (
  <Form
    method="post"
    name="event"
    data-netlify="true"
    data-netlify-honeypot="bot-field"
    style={{ paddingTop: "3rem" }}
  >
    <h2>Registrace na udalost</h2>
    <input type="hidden" name="eventid" id="eventid" value={props.eventId} />
    <input type="hidden" name="form-name" value="event" />
    <FormGroup>
      <Label for="firstname">Jméno</Label>
      <Input
        type="text"
        name="firstname"
        id="firstname"
        placeholder="Jan"
        required
      />
    </FormGroup>
    <FormGroup>
      <Label for="lastname">Příjmení</Label>
      <Input
        type="text"
        name="lastname"
        id="lastname"
        placeholder="Novák"
        required
      />
    </FormGroup>
    <FormGroup>
      <Label for="email">Email</Label>
      <Input
        type="email"
        name="email"
        id="email"
        placeholder="jan@novak.cz"
        required
      />
    </FormGroup>
    <FormGroup>
      <Label for="dateofbirth">Datum narození</Label>
      <Input type="date" name="dateofbirth" id="dateofbirth" required />
    </FormGroup>
    <FormGroup>
      <Label for="placeofresidence">Místo bydliště</Label>
      <Input
        type="text"
        name="placeofresidence"
        id="placeofresidence"
        required
      />
    </FormGroup>
    <FormGroup>
      <Label for="phone">Telefon</Label>
      <Input type="text" name="phone" id="phone" />
    </FormGroup>
    <FormGroup>
      <Label for="message">Poznámka</Label>
      <Input type="textarea" name="message" id="message" />
    </FormGroup>
    <FormGroup>
      <h6>Informace o zpracování osobních údajů</h6>
      <p className="small">
        Vaše osobní údaje jsou v bezpečí, slouží pouze pro naši interní potřebu
        organizace kurzu a nebudou poskytnuty třetí osobě. Před odesláním
        přihlášky si pozorně přečtěte{" "}
        <a
          href="https://www.tcminstitut.cz/data/soubory/430_informace-o-zpracovani-udaju.pdf"
          target="_blank"
        >
          Informace o zpracování osobních údajů.
        </a>{" "}
        Odesláním přihlášky dáváte souhlas s tímto zpracováním a svůj souhlas
        můžete kdykoliv odvolat.
      </p>
    </FormGroup>
    <Button>Registrovat se</Button>
  </Form>
);

export default EventForm;

import React from 'react';
import { graphql } from 'gatsby';
import { Container, Row, Col } from 'reactstrap';

import Layout from '../components/Layout';
import EventForm from '../components/EventForm';

const EventPageTemplate = ({ data }) => (
  <Layout>
    <Container>
      <Row>
        <Col sm={12}>
          <h1>{data.markdownRemark.frontmatter.title}</h1>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}></div>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <EventForm eventId={data.markdownRemark.frontmatter.title} />
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default EventPageTemplate;

export const eventPageQuery = graphql`
  query EventPage($id: String!) {
    markdownRemark(id: {eq: $id}) {
      html
      frontmatter {
        title
      }
    }
  }
`;